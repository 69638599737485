import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadComponent: () => import('@modules/home/home.view').then(m => m.HomeView) },
  {
    path: 'sagardobus',
    children: [
      { path: 'booking',  canActivate: [authGuard], loadComponent: () => import('@modules/booking/booking.view').then(m => m.BookingView) },
      {
        path: 'summary',
        canActivate: [authGuard],
        loadComponent: () => import('@modules/booking/booking-summary/summary.view').then(m => m.BookingSummaryView),
      },
      {
        path: 'sidrerias',
        loadComponent: () => import('@modules/sidrerias/sidrerias.view').then(m => m.SidreriasView),
      },
      {
        path: 'my-booking',
        canActivate: [authGuard],
        loadComponent: () => import('@modules/my-booking/my-booking/my-booking.view').then(m => m.MyBookingView),
      },
      {
        path: 'my-booking-summary',
        canActivate: [authGuard],
        loadComponent: () =>
          import('@modules/my-booking/my-booking-summary/summary.view').then(m => m.MyBookingSummaryView),
      },
      {
        path: 'my-booking-operations',
        canActivate: [authGuard],
        loadComponent: () =>
          import('@modules/my-booking/operations/my-booking-operations.view').then(m => m.MyBookingOperationsView),
      },
      {
        path: 'my-booking-add-passenger',
        //canActivate: [authGuard],
        loadComponent: () =>
          import('@modules/my-booking/my-booking-add-passenger/my-booking-add-passenger.view').then(m => m.MyBookingAddPassengerView),
      },
      {
        path: 'itinerary',
        loadComponent: () => import('@modules/itinerary/itinerary.view').then(m => m.ItineraryView),
      },
      {
        path: 'booking',
        children: [
          {
            path: 'payment',
            loadComponent: () => import('@modules/payment/payment.view').then(m => m.PaymentView),
            canActivate: [authGuard],
          },
        ],
      },
      {
        path: 'client',
        children: [
          {
            path: 'payment',
            canActivate: [authGuard],
            loadComponent: () =>
              import('@modules/client-payment/payment/client-payment.view').then(m => m.ClientPaymentView),
          },
          {
            path: 'more-sidrerias',
            canActivate: [authGuard],
            loadComponent: () =>
              import('@modules/client-payment/more-sidrerias/more-sidrerias.view').then(m => m.MoreSidreriasView),
          },
          {
            path: 'more-sidrerias-summary',
            canActivate: [authGuard],
            loadComponent: () =>
              import('@modules/client-payment/more-sidrerias-summary/more-sidrerias-summary.view').then(
                m => m.MoreSidreriasSummaryView
              ),
          },
          {
            path: 'generation',
            canActivate: [authGuard],
            loadComponent: () =>
              import('@modules/client-payment/generation/generation.view').then(m => m.GenerationView),
          },
          {
            path: 'generation-summary',
            canActivate: [authGuard],
            loadComponent: () =>
              import('@modules/client-payment/generation-summary/generation-summary.view').then(
                m => m.GenerationSummaryView
              ),
          },
        ],
      },
      {
        path: 'sidreria',
        children: [
          {
            path: 'confirmation',
            canActivate: [authGuard],
            loadComponent: () => import('@modules/confirmation/confirmation.view').then(m => m.ConfirmationView),
          },
          {
            path: 'mybooking',
            canActivate: [authGuard],
            loadComponent: () =>
              import('@modules/my-booking-confirmation/my-booking-confirmation.view').then(
                m => m.MyBookingConfirmationView
              ),
          },
        ],
      },
    ],
  },
  { path: 'howto', loadComponent: () => import('@modules/faq/faq.view').then(m => m.FaqView) },
  { path: 'contact', loadComponent: () => import('@modules/contact/contact.view').then(m => m.ContactView) },
  { path: 'politic', loadComponent: () => import('@modules/politic/politic.view').then(m => m.PoliticView) },
  {
    path: 'covidPolitic',
    loadComponent: () => import('@modules/covid-politic/covid-politic.view').then(m => m.CovidPoliticView),
  },
  { path: 'cookies', loadComponent: () => import('@modules/cookies/cookies.view').then(m => m.CookiesView) },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      // anchorScrolling: 'enabled',
      // scrollOffset: [0, 64] // [x, y]
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
